$fontFamilyLogin: 'SharpGrotesk', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;

$fontFamily: 'Graphik LC Web', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;

$mainTextColor: #0073f7;
$secondaryTextColor: #747e8a;
$textDark: #00244d;

$mainColor: #0073f7;
$darkThemeColor: #00244d;

$loginContainerWidth: 1440px;
$containerWidth: 1040px;

$reduxErrorColor: #eb2124;
$bordersColor: #c5d0db;
$disabledColor: #a0abb8;

:root {
  --fontFamilyLogin: 'SharpGrotesk', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;

  --fontFamily: 'Graphik LC Web', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;

  --fontFamilySpaceMono: 'SpaceMono', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;

  --mainTextColor: #0073f7;
  --secondaryTextColor: #747e8a;
  --textDark: #00244d;

  --mainColor: #0073f7;
  --darkThemeColor: #00244d;

  --loginContainerWidth: 1440px;
  --containerWidth: 1040px;

  --reduxErrorColor: #eb2124;
  --bordersColor: #c5d0db;
  --disabledColor: #a0abb8;
}
