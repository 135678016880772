@import 'src/app/variables';
@import '/node_modules/@xsolla/gdpr/dist/bundle.css';

$max-content-width: 1440px;
$min-content-width: 320px;
$icons-size: 20px;

:global .gdpr-app {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.3);

  --background-color: white !important;
}

:global .gdpr-container {
  padding: 24px !important;
}

:global .gdpr-settings-main {
  height: 192px;
}

:global .gdpr-settings-content {
  height: 192px !important;
  padding: 16px !important;

  --tooltip-background-color: #f7faff;
  --tooltip-text-color: $textDark;

  &__link {
    font-weight: 500 !important;
  }

  a {
    color: $mainColor !important;
    text-decoration: underline !important;
  }
}

:global .gdpr-settings-screen {
  &__title {
    font-size: 18px !important;
  }
}

:global .gdpr-settings-tab {
  &__btn {
    --contrast-accent-color: #f7faff;
    --background-color-secondary: white;
    padding: 6px 4px !important;
  }
}

:global .gdpr-settings-footer {
  margin-top: 60px !important;

  &__buttons-container {
    background-color: white;
  }

  &__links-container {
    align-self: flex-end;
  }

  &__link {
    color: $mainColor !important;
    font-weight: 500 !important;
  }
}

:global .gdpr-button {
  border-color: $mainColor !important;
  border-radius: 100px !important;

  &--primary {
    box-shadow: 0 4px 16px 0 rgba(0, 115, 247, 0.48);

    &:hover {
      border: 1.5px solid #0067de;
      background-color: #0067de !important;
      box-shadow: 0 8px 16px #0073f752;
    }
  }

  &--secondary {
    color: $mainColor !important;

    &:hover {
      border: 1.5px solid $mainColor;
      background-color: rgba(0, 115, 247, 0.08) !important;
    }
  }
}

:global .gdpr-checkbox {
  --checkbox-color: white !important;
  --checkbox-border-color: #a0abb8 !important;

  &__border {
    border: 1px solid var(--checkbox-border-color);
    border-radius: 5px !important;
  }
}