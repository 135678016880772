.submit_status {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 12px;
  border: 1px solid;
  margin-bottom: 24px;
  border-radius: 6px;

  &_error {
    border-color: #ffc5b3;
    background-color: #ffeded;
    color: #f50031;
  }

  &_success {
    border-color: rgba(0, 210, 166, 0.16);
    background-color: #ecfcf8;
    color: #00c77e;
  }

  &_alert {
    border-color: #c5dffd;
    background-color: #ebf4fe;
    color: var(--colorBrand);
  }

  &_warning {
    border-color: #ffae66;
    background-color: rgba(255, 129, 17, 0.08);
    color: var(--colorOrange);
  }

  &__children {
    margin-left: 12px;
  }

  &__icon {
    max-height: 22px;
    background-repeat: no-repeat;
    background-size: auto;

    &_error {
      content: url("../../../images/icons/alert--red-transparent.svg");
    }

    &_success {
      content: url("../../../images/icons/checkmark.svg?stroke=#00c77e");
    }

    &_alert {
      content: url("../../../images/icons/alert--blue.svg");
    }

    &_warning {

    }
  }
}