@import '~@xsolla/uikit/lib/colors.css';

.discoverModal {
  color: var(--colorTextBlack);
}

.filterForm {
  margin-top: 32px;
}

.filterForm .checkbox {
  margin-top: -25px;
}

.filterForm .checkboxWidth > div {
  width: 50%;
}

.filterForm .resetFilters {
  color: var(--colorBrand);
  cursor: pointer;
  font-weight: bold;
  user-select: none;
}

.filterForm .footer {
  position: sticky;
  bottom: 0;
  background: #ffffff;
}
