.radioGroup {
  padding: 8px;
  margin: -8px;
  transition: background-color 80ms ease-in-out;
}

.radioGroup.error {
  margin-bottom: 0;
  background-color: rgba(245, 0, 49, 0.08);
  border-radius: 6px;
}

.radioGroup:hover {
  background-color: transparent;
}