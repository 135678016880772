@import '../../../../../../node_modules/@xsolla/uikit/lib/colors.css';

:root {
  --dot-size: 6px;
  --circle-size: 16px;
}

.arrow {
  display: flex;
  color: var(--colorTextSecondary);
  cursor: pointer;
}

.arrow > i {
  font-size: 16px !important;
}

.arrow:hover {
  color: var(--colorBrand);
}

.arrow.left {
  margin-right: 20px;
}

.arrow.right {
  margin-left: 20px;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.dot {
  display: inline-block;
  width: var(--dot-size);
  height: var(--dot-size);
  margin: 0 var(--dot-size);
  background-color: var(--colorTextBlack);
  border-radius: var(--dot-size);
  opacity: 0.64;
}

.circleLoader {
  display: flex;
  width: var(--circle-size);
  height: var(--circle-size);
  margin: 0 10px;
  justify-self: center;
  transform: rotate(90deg);
}
