@import '~@xsolla/uikit/lib/colors.css';

.item.select {
  width: 200px;
}

.item.query {
  width: 220px;
}

.item.select.small {
  width: 180px;
}

.item.more {
  width: 138px;
}

.item.more.icon {
  width: unset;
}

.moreFilter {
  display: flex;
  white-space: nowrap;
}

.moreFilter .modalIcon {
  margin-right: 10px;
}

.moreFilter .count {
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  background-color: var(--colorBrand);
  border-radius: 100px;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
  white-space: nowrap;
}
