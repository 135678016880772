.filterGroup {
  padding-bottom: 44px;
  border-bottom: 1px #C5D0DB solid;
  margin-bottom: 44px;
}

.filterGroup:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.label {
  margin-bottom: 32px;
  font-size: 15px;
  font-weight: bold;
}