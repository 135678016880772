.unsubscribePage {
  width: 532px;
  margin: 0 auto;
  text-align: center;
}

.successMessage {
  padding: 64px;
  text-align: center;
}

.title {
  margin: 0 0 8px 0;
  color: #00244D;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 32px;
}

.link {
  display: inline-flex;
  margin-top: 44px;
  font-weight: bold;
}