.discoverSort {
  display: flex;
  align-items: center;
}

.discoverSort .text {
  margin-right: 24px;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
}

.discoverSort .select {
  width: 240px;
}

.discoverSort .select > div div:last-child {
  width: auto;
}
