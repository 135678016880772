.grid {
  display: flex;
  flex-basis: auto;
  flex-shrink: 1;
  margin: -8px -8px 8px -8px;
}

.grid:last-child {
  margin-bottom: -8px;
}

.column {
}

.item {
  height: 100%;
  padding: 8px;
}
