@import '~@xsolla/uikit/lib/colors.css';

.amountTag {
  display: flex;
  width: min-content;
  align-self: flex-start;
  padding: 0 4px;
  background-color: var(--mainColor);
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
}
