.work_area {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 24px 32px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 32px 0 hsla(210,7%,78%,.48), 0 1px 4px 0 rgba(0,36,77,.08);
  transition: background-color .1s ease-in-out,color .1s ease-in-out,box-shadow .1s ease-in-out;
}