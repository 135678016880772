@import '~@xsolla/uikit/lib/colors.css';

.tag {
  display: inline-block;
  width: min-content;
  padding: 0 8px;
  border: 1px solid var(--color-border--regular);
  margin-right: var(--ls);
  border-radius: 4px;
  color: var(--colorTextSecondary);
  font-size: 11px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
}
