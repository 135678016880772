@import "src/app/variables";

.userPanel {
  display: flex;
  align-items: center;
  user-select: none;
}

.aside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 12px;
  animation: asideAnimation ease-in-out 120ms;
  text-align: right;
}

@keyframes asideAnimation {
  from {
    opacity: 0;
    transform: translateX(4px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.name {
  font-size: 13px;
  line-height: 20px;
}

.company {
  height: 16px;
  color: #747E8A;
  font-size: 13px;
  line-height: 16px;
}

.verify {
  margin-top: 1px;
}

.verifyTrigger {
  cursor: pointer;
}

.trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.trigger.active .chevron {
  transform: rotate(180deg);
}

.trigger.active .icon,
.trigger:hover .icon {
  border: 1px solid $mainColor;
}

.icon {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.chevron {
  margin-left: 6px;
  transition: transform ease-in-out 40ms;
}
