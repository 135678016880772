.darkText {
  color: var(--colorTextBlack);
}

.uiFontFamily {
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 1.5
}

.alertTitle {
  margin: 0 calc(var(--ls--xxlg) * -1);
}

.alertDescription {
  max-width: 304px;
}

.title {
  margin-bottom: 16px;
  color: var(--colorTextBlack);
}

.container {
  position: relative;
  display: flex;
}

.content {
  margin-right: 44px;
}

.aside {
  width: 284px;
  flex-shrink: 0;
}

.stickyFooter {
  position: sticky;
  bottom: -80px;
  left: 0;
  background-color: #FFFFFF;
}

.notification {
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid rgba(255, 129, 17, 0.64);
  background: rgba(255, 129, 17, 0.08);
  border-radius: 4px;
  color: #FF8111;
  font-family: var(--fontFamily);
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
}