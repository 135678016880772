@mixin fontFace($family, $src, $style: normal, $weight: normal, $stretch: normal) {
  @font-face {
    font-family: $family;
    font-stretch: $stretch;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$src}.eot');
    src: url('#{$src}.eot?#iefix') format('embedded-opentype');
    src: url('#{$src}.woff') format('woff');
    src: url('#{$src}.woff2') format('woff2');
  }
}

@include fontFace('SharpGrotesk', '../assets/fonts/sharp-grotesk/15/Bold/Bold', normal, 900);
@include fontFace('SharpGrotesk', '../assets/fonts/sharp-grotesk/20/Book/Book');
@include fontFace('SharpGrotesk', '../assets/fonts/sharp-grotesk/20/Medium/Medium', normal, bold);
@include fontFace('SharpGrotesk', '../assets/fonts/sharp-grotesk/15/Semibold/SharpGroteskSmBold15', normal, 800);

@include fontFace('SpaceMono', '../assets/fonts/space-mono/SpaceMono-Regular');
@include fontFace('SpaceMono', '../assets/fonts/space-mono/SpaceMono-Bold', normal, bold);
