@import '../../../app/variables.scss';

.formSection {
  display: flex;
  align-items: flex-start;
  margin-bottom: 44px;
}

.formSection:last-child {
  margin-bottom: 0;
}

.label {
  width: 140px;
  flex-shrink: 0;
  margin-right: 24px;
  color: var(--secondaryTextColor);
  font-size: 11px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.content {
  width: 100%;
}