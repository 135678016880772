@import 'variables';

body {
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 24px;
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  color: $textDark;

  &__background_dark {
    background-color: $darkThemeColor;
  }

  &__background_light {
    background-color: #f7faff;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

a {
  color: $mainTextColor;
  text-decoration: none;
}
