.fieldGroup {
  display: flex;
  align-items: center;
}

.fieldGroup.justify-default {
  justify-content: flex-start;
}

.fieldGroup.justify-between {
  justify-content: space-between;
}

.fieldGroup.justify-center {
  justify-content: center;
}

.fieldGroup.size-small > .child {
  margin-right: 16px;
}

.fieldGroup.size-default > .child {
  margin-right: 24px;
}

.fieldGroup.size-large > .child {
  margin-right: 32px;
}

.child {
  display: flex;
  align-items: center;
}

.child:last-child {
  margin-right: 0 !important;
}

.child.stretch {
  width: 100%;
}