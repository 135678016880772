@import '../../../app/variables.scss';

.infoCard {
  height: 100%;
}

.infoCard.bordered {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid var(--bordersColor);
  border-radius: 4px;
}

.infoCard.bordered .label {
  margin-bottom: 8px;
}

.infoCard.bordered .entry {
  font-weight: bold;
}

.infoCard.flatten {
  padding-left: 16px;
  border-left: 1px solid var(--bordersColor);
}

.infoCard.flatten .label {
  margin-bottom: 8px;
}

.label {
  color: var(--secondaryTextColor);
  font-size: 14px;
  line-height: normal;
}

.entry {
  line-height: normal;
}