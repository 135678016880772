@import '~@xsolla/uikit/lib/colors.css';

.multiCheckboxContainer {
  display: flex;
  height: inherit;
  flex-direction: column;
}

.multiCheckbox {
  display: flex;
  height: inherit;
  flex-direction: column;
  flex-flow: wrap;
  padding: 8px;
  margin: -8px;
  transition: background-color 80ms ease-in-out;
}

.multiCheckbox > div {
  width: 50%;
}

.multiCheckbox.error {
  margin-bottom: 0;
  background-color: rgba(245, 0, 49, 0.08);
  border-radius: 6px;
}

.multiCheckbox:hover {
  background-color: transparent;
}

.multiCheckbox.selectAll {
  height: min-content;
  align-self: flex-end;
  margin-bottom: 8px;
  color: var(--color-disabled-text);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  user-select: none;
}

.multiCheckbox.selectAll.active {
  color: var(--colorBrand);
}
