.declinePage {
  width: 532px;
  margin: 0 auto;
  text-align: center;
}

.warningMessage {
  padding: 64px;
  text-align: center;
}

.icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.title {
  margin: 0 0 8px 0;
  color: #00244D;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 32px;
}
