.slickPlaceholder {
  height: min-content;
}

.placeholder {
  animation: placeHolderShimmer 3s linear infinite;
  background: linear-gradient(to right, #F5F6F8 8%, #efeff3 20%, #F5F6F8 33%);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -952px 0;
  }

  100% {
    background-position: 952px 0;
  }
}