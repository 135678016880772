@import '~@xsolla/uikit/lib/colors.css';
@import '~@xsolla/uikit/lib/variables.css';

:root {
  --border-raduis: 100px
}

.progressBar {
  width: 100%;
  height: 4px;
  margin-bottom: 8px;
  background: linear-gradient(
    90.04deg,
    rgba(255, 255, 255, 0.2) -0.15%,
    rgba(255, 255, 255, 0) 100.14%
  ),
    rgba(0, 36, 77, 0.16);
  border-radius: var(--border-raduis);
}

.percentage {
  max-width: inherit;
  height: inherit;
  background: var(--colorGreen);
  border-radius: var(--border-raduis);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}