.infoRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.infoRow:last-child {
  margin-bottom: 0;
}

.label {
  padding-right: 24px;
  color: var(--secondaryTextColor);
  font-size: 14px;
  line-height: 20px;
}

.entry {
  width: 40%;
  flex-shrink: 0;
  margin-left: auto;
  text-align: left;
}