.notification {
  box-sizing: border-box;
  padding: 8px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
}

.notification.info {
  border-color: rgba(0, 118, 255, 0.64);
  background-color: rgba(0, 118, 255, 0.08);
  color: var(--colorBrand);
}

.notification.success {
  border-color: rgba(0, 199, 126, 0.64);
  background-color: rgba(0, 199, 126, 0.08);
  color: #00C77E;
}

.notification.warning {
  border-color: rgba(255, 129, 17, 0.64);
  background-color: rgba(255, 129, 17, 0.08);
  color: #FF8111;
}

.notification.muted {
  border-color: #C5D0DB;
  background-color: #F7FAFF;
  color: #00244D;
}