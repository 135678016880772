.settingsContainer {
  display: flex;
  align-items: flex-start;
}

.aside {
  flex-shrink: 0;
  margin-right: 16px;
}

.entry {
  width: 100%;
}
