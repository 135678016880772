@import "src/app/variables";

.sidebar {
  width: 336px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 32px 0 rgba(0, 36, 77, 0.08), 0 1px 4px 0 rgba(0, 36, 77, 0.08);
}

.link {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px 10px 16px;
  border-radius: 6px;
  color: $textDark;
  font-size: 15px;
  font-weight: bold;
  transition: background-color 80ms ease-in-out, color 80ms ease-in-out;
}

.link.active {
  background-color: $mainColor;

  color: white;
  pointer-events: none;
}